import React from 'react';
import PropTypes from 'prop-types';
import styles from './TeaserTitle.module.scss';

const TeaserTitle = ({ title, icon, small }) => (
  <div className={[styles.titleWrapper, small ? styles.small : null].join(' ')}>
    <div className={styles.iconWrapper}>
      <img src={icon} alt="medical" />
    </div>
    <h3>{title}</h3>
  </div>
);

TeaserTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  small: PropTypes.bool,
};

TeaserTitle.defaultProps = {
  small: false,
};

export default TeaserTitle;
