import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
// import { Link } from 'gatsby-plugin-modal-routing';
import { Container, Col, Row } from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeaderSection from '../components/HeaderSection/HeaderSection';
import TeaserTitle from '../components/TeaserTitle/TeaserTitle';

import bild01 from '../images/icons/017-treatment.png';
import bild02 from '../images/icons/011-acupuncture-1.png';
import bild03 from '../images/icons/035-joint.png';
import bild04 from '../images/icons/034-hand.png';
import bild05 from '../images/icons/015-foot-massage.png';
import bild06 from '../images/icons/030-tape.png';
import bild07 from '../images/icons/018-patient.png';
import bild08 from '../images/icons/039-geometric.png';
import bild09 from '../images/icons/008-spine-1.png';
import bild010 from '../images/icons/040-target.png';

const Diagnostik = () => (
  <Layout>
    <SEO title="Behandlung" />
    <HeaderSection title="Behandlung" />
    <Container>
      <Row>
        <Col xs="12" sm="10">
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="ACP/PRP/Biologics" icon={bild01} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                Beide neuartigen biologischen Behandlungsverfahren nutzen
                patienteneigenes Blut, das durch spezielle Trennverfahren
                aufbereitet wird.
                  {' '}
                  <br />
                  <br />
Bei der ACP (autologes conditioniertes Plasma) Therapie werden die
durch die Aufarbeitung gewonnenen konzentrierten Wachstumsfaktoren
mithilfe einer sterilen Doppelkammerspritze in die  betroffenen
Gelenke oder Sehnen/Muskeln injiziert. Die Therapie ist nicht nur
hocheffizient bei Arthrosen, sondern auch bei entzündlichen oder
verschleissbedingten Sehnenveränderungen und Muskelverletzungen einsetzbar.
                  <br />
                  <br />
Die A-PRP (autologes plättchen-reiches Plasma) Therapie kann
isoliert oder aber in Kombination mit Hyaluronsäure eingesetzt
werden. Hierbei werden die seit über 25 Jahren bekannten positiven
Eigenschaften der Hyaluronsäure  - wie z.B. verbesserte Gelenkschmierung
-  zusammen mit der regenerativen Kraft der Eigenblutbestandteile
genutzt, um eine noch bessere Wirkung bei Arthrosen zu erzielen.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Akupunktur" icon={bild02} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                Akupunktur ist das Stechen von Nadeln an bestimmten Punkten des Körpers.
                Es ist die älteste Heilkunst, schulmedizinisch seit Jahrzehnten in Europa
                etabliert, dessen Wirkung wissenschaftlich nach  Studien mit über 500.000
                Menschen in Deutschland belegt und seit 2007 dementsprechend durch die
                Krankenkassen bei chronischen Kniegelenks- und Rückenbeschwerden im
                Leistungskatalog verankert ist.
                  {' '}
                  <br />
                  <br />

Sie ist ein wichtiger, unverzichtbarer Bestandteil der Schmerztherapie.
                  <br />
                  <br />

Sie liefert nicht nur hervorragende Ergebnisse bei allen akuten und chronischen
Schmerzen des Bewegungsapparates, sondern wird als Regulationstherapie auch im
Rahmen der Suchtbehandlung (Nikotinentwöhnung), bei Erschöpfungssyndrome
(z.B. burn out) oder bei  Kopfschmerz und Schwindel sehr effektiv eingesetzt.
                  <br />
                  <br />

Sprechen Sie uns bitte an!

                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Arthrosetherapie" icon={bild03} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                Als Arthrose werden umgangssprachlich verschleiss- oder
                abnutzungsbedingte Gelenkveränderungen verstanden. Die
                Schädigung des Gelenkknorpels kann unterschiedlich stark
                sein, lokalisiert, kann zu Entzündung, Schmerz und Schwellung
                führen und die Belastungsfähigkeit des Menschen beeinträchtigen.
                  <br />
Hieraus ergeben sich auch unterschiedliche Therapieoptionen:  antientzündlich,
rein schmerzlindernd, regenerativ, schonend oder minimal-invasiv mittels Injektionen/Spritzen.
                  <br />
                  <br />

Die effektivste Form der Therapie stellen die Gelenkinjektionen/Spritzen dar.
Diese nehmen wir unter hochsterilen Bedingungen in speziellen schmerzarmen Techniken vor.
                  <br />
                  <br />

Wir bieten alle modernen schulmedizinischen Therapieformen mit Hyaluron und/ oder
Eigenblut (ACP/PRP) inklusiver homöpathischer Verfahren an.
                  <br />
                  <br />

Lassen Sie uns den besten Weg für Sie finden.

                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Chirotherapie" icon={bild04} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel />
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Einlagen" icon={bild05} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel />
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Kinesiotaping" icon={bild06} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                Beim  Kinesiotape handelt es sich um ein elastisches Baumwollband
                mit einer Acrylklebeschicht. Das Tape wird unter Zug und Dehnung
                angebracht und entfaltet in Abhängigkeit der Klebetechnik
                muskelentspannende, -stärkende oder abschwellende Wirkung. Es
                ist stoffwechselanregend und schmerzlindernd, es schränkt die
                Bewegung nicht ein, ist wasserbeständig und hält bis zu 7 Tagen auf der Haut.
                  <br />
                  <br />

 Es lässt sich bei fast allen Gelenk- und Muskelstörungen anwenden und
 wird insbesondere im Freizeit- und Leistungssport bei Verletzungen oder präventiv eingesetzt.


                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Notfall" icon={bild07} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                Wir bieten täglich  eine Notfallsprechstunde für unsere Patienten
                an. Zur besseren Planung möchten wie Sie bitten, wenn möglich,
                den Termin vorher telefonisch oder gerne auch per email zu erfragen.
                  <br />
 Bitte haben Sie  Verständnis dafür, dass im Rahmen des Termins keine allumfassende
 Behandlung erfolgen kann. Wir werden alles tun, um Ihr akutes Problem zu lösen,
 weiterführende Untersuchungen dann zeitnah terminieren.

                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Regulationsmedizin" icon={bild08} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel />
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Rückenschmerz" icon={bild09} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                In Abhängigkeit von Beschwerdestärke und Untersuchungsbefund gibt
                es eine Vielzahl möglicher Therapieoptionen. Oftmals reichen eine
                kurzfristige  Medikamenteneinnahme, gezielte Übungsbehandlungen und
                physikalischen Massnahmen zur Schmerzbeseitigung aus.
                  <br />
                  <br />

Bei fortbestehenden  Beschwerden oder klinischen Zeichen einer
Nervenwurzelkompression  setzen wir auf ein kombiniertes Therapiekonzept.
                  <br />
Dieses beinhaltet neben obigen Therapien manuelle Verfahren ggf mit Kinesiotaping,
Triggerpunkt- oder klassischer Akupunktur und insbesondere ultraschallgesteuerte,
strahlungsfreie Injektionen  an den Nervenaustrittspunkten.


                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <TeaserTitle small title="Schmerztherapie" icon={bild010} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                Klassisch wird unter einer Schmerztherapie zumeist der Einsatz von
                Medikamenten in Tablettenform oder Infusion verstanden.
                  {' '}
                  <br />
Der moderne Ansatz ist multimodal, heisst, man versucht entsprechend des
Schweregrades und den Bedürfnissen des Menschen die Therapie individuell anzupassen.
                  {' '}
                  <br />
                  <br />

Diese reichen einerseits von schulmedizinischen Therapieformen wie
physikalischen Massnahmen, Chirotherapie, Kinesiotaping, Infusionen,
Physiotherapie, Akupunktur bis hin zu gezielten, ultraschallgesteuerten Injektionen.
                  <br />
                  <br />

Andererseits setzen wir auf ganzheitliche Therapien, die die gestörten
Funktionsabläufe korrigieren und harmonisieren helfen. Diese können sowohl
stimulierend als Ozontherapie, als auch störfeldgebunden  im Rahmen der
Neuraltherapie oder  Mesotherapie erfolgen.
                  <br />
                  <br />

Nicht alle zur Verfügung stehenden Therapieformen werden von den gesetzlichen
Krankenkassen übernommen.
                  <br />
                  <br />

Sprechen Sie uns bitte an, wir werden Sie umfassend beraten und den bestmöglichen Weg finden.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default Diagnostik;
